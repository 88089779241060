<template>
  <div id="reviews">
    <tmpl_header />

    <div class="container">
      <div class="breadcrumb">
        <ul>
          <li class="r">
            <router-link to="/center">会员中心</router-link>
          </li>
          <li class="r">
            <router-link to="/center/reviews">我的评价</router-link>
          </li>
          <li>
            <a href="javascript:;">评价详情</a>
          </li>
        </ul>
      </div>

      <div class="card info">
        <h3>评价详情</h3>
        <p>
          <label>订单号：2021873271536165</label
          ><label>下单时间：2021-12-01 12:00:03</label>
        </p>
      </div>

      <div class="card reviews">
        <p>1件商品已评价</p>
        <ul>
          <li>
            <div class="product">
              <img src="../../assets/demo/pro.jpg" />
              <h4>
                Apple iPhone 13 Pro Max (A2644) 256GB 远峰蓝色 支持移动联通电信
              </h4>
              <p>￥9785.50<small>x1</small></p>
            </div>
            <div class="form">
              <p>
                <label>商品评价</label>
                <span class="star">
                  <i class="act"></i>
                  <i></i>
                  <i></i>
                  <i></i>
                  <i></i>
                </span>
              </p>
              <p>
                <label>评价内容</label>
                <span>很快就到货了，非常喜欢，好评！</span>
              </p>
            </div>
          </li>
        </ul>
      </div>
    </div>

    <tmpl_footer />
  </div>
</template>

<script>
import tmpl_header from "../../components/common/header.vue";
import tmpl_footer from "../../components/common/footer.vue";
import api from "../../api/index";
// import apis from "../../api/api";
import store from "../../store/index";

export default {
  data() {
    return {
      loading: false,
    };
  },

  components: {
    tmpl_header,
    tmpl_footer,
  },

  computed: {
    username() {
      return store.getters.customerName;
    },
  },

  created: function () {
    if (!this.username)
      this.$router.push({
        path: "/signin",
        query: { to: encodeURI("/center/info") },
      });

    api.all([this.loadData()]).then(
      api.spread(() => {
        this.loading = false;
      })
    );
  },

  methods: {
    loadData: function () {},
  },
};
</script>

<style lang="less">
@import url("../../assets/less/common.less");
@import url("./reviews.less");
</style>
